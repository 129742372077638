import platformApi from 'api/platformApi/platformApi';
import {
  ICreateWorkspaceRequestBody,
  ICreateWorkspaceResponse,
  IPlatformApiGenericError,
} from 'api/platformApi/platformApi.types';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { ErrorSchema, InterfaceOperationError, isWorkspaceNameExistsError } from 'utils/httpErrors';
import { CONFLICT } from 'utils/httpStatuses';

export const createWorkspace = async (
  workspaceData: ICreateWorkspaceRequestBody,
  axiosConfig?: AxiosRequestConfig,
) => {
  try {
    const response = await platformApi.post<
      ICreateWorkspaceResponse,
      AxiosResponse<ICreateWorkspaceResponse>,
      ICreateWorkspaceRequestBody
    >(`/workspaces`, workspaceData, axiosConfig);

    return response.data;
  } catch (error) {
    const apiError = CreateWorkspaceOperationError.unwrapAPIError(error);
    if (apiError) throw new CreateWorkspaceOperationError(apiError);
    throw error;
  }
};

type CreateWorkspaceOperationErrorSchema = ErrorSchema<typeof CONFLICT>;

export class CreateWorkspaceOperationError extends InterfaceOperationError<
  Omit<ICreateWorkspaceRequestBody, 'description' | 'logoUrl'>,
  CreateWorkspaceOperationErrorSchema
> {
  errorSchema: CreateWorkspaceOperationErrorSchema = {
    [CONFLICT]: (apiError) => {
      if (isWorkspaceNameExistsError(apiError)) {
        this.scope = 'field';
        this.field = 'name';
        this.message = 'A workspace already exists with this name.';
      }
    },
  };

  constructor(apiError: IPlatformApiGenericError) {
    super(apiError, 'An error occurred and the workspace could not be created.');
    this.scopeAPIError();
  }
}
