import platformApi from 'api/platformApi/platformApi';
import {
  ICreateWorkspaceProjectRequestBody,
  ICreateWorkspaceProjectResponse,
  IPlatformApiGenericError,
} from 'api/platformApi/platformApi.types';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  ErrorSchema,
  InterfaceOperationError,
  isProjectNameEmptyError,
  isProjectNameExistsError,
} from 'utils/httpErrors';
import { BAD_REQUEST, CONFLICT } from 'utils/httpStatuses';

export const createWorkspaceProject = async (
  workspaceId: string,
  projectData: ICreateWorkspaceProjectRequestBody,
  axiosConfig?: AxiosRequestConfig,
) => {
  try {
    const response = await platformApi.post<
      ICreateWorkspaceProjectResponse,
      AxiosResponse<ICreateWorkspaceProjectResponse>,
      ICreateWorkspaceProjectRequestBody
    >(`/workspaces/${workspaceId}/projects`, projectData, axiosConfig);

    return response.data;
  } catch (error) {
    const apiError = CreateProjectOperationError.unwrapAPIError(error);

    if (apiError) {
      throw new CreateProjectOperationError(apiError);
    }

    throw error;
  }
};

type CreateProjectOperationErrorSchema = ErrorSchema<typeof BAD_REQUEST | typeof CONFLICT>;

export class CreateProjectOperationError extends InterfaceOperationError<
  ICreateWorkspaceProjectRequestBody,
  CreateProjectOperationErrorSchema
> {
  errorSchema: CreateProjectOperationErrorSchema = {
    [BAD_REQUEST]: (apiError) => {
      if (isProjectNameEmptyError(apiError)) {
        this.scope = 'field';
        this.field = 'name';
        this.message = 'A project title is required.';
      }
    },
    [CONFLICT]: (apiError) => {
      if (isProjectNameExistsError(apiError)) {
        this.scope = 'field';
        this.field = 'name';
        this.message = 'A project already exists with this name.';
      }
    },
  };

  constructor(apiError: IPlatformApiGenericError) {
    super(apiError);
    this.scopeAPIError();
  }
}
