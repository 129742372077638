import platformApi from 'api/platformApi/platformApi';
import {
  IUpdateUserServiceParamsPath,
  IUpdateUserServiceRequestBody,
  IUpdateUserServiceResponse,
} from 'api/platformApi/platformApi.types';

export const updateUserService = async ({
  params: {
    path: { userId },
  },
  body,
}: ServiceFnConfig<IUpdateUserServiceParamsPath, undefined, IUpdateUserServiceRequestBody>) => {
  const response = await platformApi.put<IUpdateUserServiceResponse>(`/users/${userId}`, body);

  return response.data;
};
