import { Table, TableCell, TableSortLabel, Typography } from '@mui/material';
import useWorkspaces from 'api/platformApi/services/workspacesService/useWorkspaces';
import MFTableBody from 'components/ui/MFTableBody/MFTableBody';
import MFTableBodyRow from 'components/ui/MFTableBodyRow/MFTableBodyRow';
import MFTableContainer from 'components/ui/MFTableContainer/MFTableContainer';
import MFTableHead from 'components/ui/MFTableHead/MFTableHead';
import {
  ITableHeadCell,
  ITableOrderDirection,
} from 'components/ui/MFTableHeadCell/MFTableHeadCell.types';
import MFTableHeadRow from 'components/ui/MFTableHeadRow/MFTableHeadRow';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getWorkspacesComparator } from 'utils/comparators/workspace';
import { formatRelativeDate } from 'utils/dates';
import { WORKSPACE_DEFAULT_LOGO } from 'utils/mediaPaths';
import { routes } from 'utils/routes';
import * as Styled from './WorkspacesPage.styles';
import { IWorkspaceWithDescription, IWorkspacesOrderBy } from './WorkspacesPage.types';
import WorkspacesFeedback from './components/WorkspacesFeedback/WorkspacesFeedback';
import WorkspacesLoader from './components/WorkspacesLoader/WorkspacesLoader';
import WorkspacesPageHeader from './components/WorkspacesPageHeader/WorkspacesPageHeader';

const WorkspacesPage = () => {
  const [orderBy, setOrderBy] = useState<IWorkspacesOrderBy>('name');
  const [orderDirection, setOrderDirection] = useState<ITableOrderDirection>('asc');

  const navigate = useNavigate();

  const workspacesQuery = useWorkspaces();
  const workspaces = workspacesQuery.data?.data ?? [];
  const sortedWorkspaces: IWorkspaceWithDescription[] = workspaces
    .map((workspace) => ({
      ...workspace,
      description: workspace.description ? workspace.description : '',
    }))
    .sort(getWorkspacesComparator(orderDirection, orderBy));

  const headCells: ITableHeadCell<IWorkspacesOrderBy>[] = [
    { id: 'name', label: 'Name' },
    { id: 'description', label: 'Description' },
    { id: 'createdAt', label: 'Created' },
  ];

  const handleSort = (headCellId: IWorkspacesOrderBy) => {
    if (orderBy === headCellId) {
      setOrderDirection((orderDirection) => (orderDirection === 'desc' ? 'asc' : 'desc'));
      return;
    }

    setOrderBy(headCellId);
    setOrderDirection('asc');
  };

  if (workspacesQuery.isLoading) {
    return <WorkspacesLoader headCells={headCells} rows={8} />;
  }

  if (workspacesQuery.isError) {
    return (
      <WorkspacesFeedback
        title="Workspaces failed to load"
        description="An error occurred and workspaces could not be loaded. Please try refreshing the page to access workspaces."
      />
    );
  }

  if (workspaces.length === 0) {
    return (
      <WorkspacesFeedback
        title="No workspaces created yet"
        description="Workspaces are a place to organize your projects around a common brand, department, or goal. Create your first workspace with the “Create workspace” action above."
      />
    );
  }

  return (
    <>
      <WorkspacesPageHeader />

      <MFTableContainer>
        <Table>
          <MFTableHead>
            <MFTableHeadRow>
              {headCells.map((headCell) => (
                <Styled.CustomMFTableHeadCell variant="head" key={headCell.id}>
                  <TableSortLabel
                    onClick={() => {
                      handleSort(headCell.id);
                    }}
                    active={headCell.id === orderBy}
                    direction={orderDirection}
                    hideSortIcon={false}
                  >
                    {headCell.label}
                  </TableSortLabel>
                </Styled.CustomMFTableHeadCell>
              ))}
            </MFTableHeadRow>
          </MFTableHead>

          <MFTableBody>
            {sortedWorkspaces.map((workspace) => (
              <MFTableBodyRow
                key={workspace.id}
                onClick={() => {
                  navigate(routes.adminWorkspace(workspace.id));
                }}
                sx={{ cursor: 'pointer' }}
              >
                <TableCell>
                  <Styled.NameContent>
                    <Styled.WorkspaceLogo
                      src={workspace.logoUrl ? workspace.logoUrl : WORKSPACE_DEFAULT_LOGO}
                    ></Styled.WorkspaceLogo>
                    <Styled.Name variant="titleSmall" color="text.primary">
                      {workspace.name}
                    </Styled.Name>
                  </Styled.NameContent>
                </TableCell>
                <Styled.DescriptionTableCell>
                  <Typography variant="bodyMedium" color="text.secondary">
                    {workspace.description ? workspace.description : '-'}
                  </Typography>
                </Styled.DescriptionTableCell>
                <TableCell>
                  <Typography variant="bodyMedium" color="text.secondary">
                    {formatRelativeDate(workspace.createdAt)}
                  </Typography>
                </TableCell>
              </MFTableBodyRow>
            ))}
          </MFTableBody>
        </Table>
      </MFTableContainer>
    </>
  );
};

export default WorkspacesPage;
