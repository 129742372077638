import { ITableOrderDirection } from 'components/ui/MFTableHeadCell/MFTableHeadCell.types';
import { IWorkspace } from '../../api/platformApi/platformApi.types';

type WorkspaceOrderBy = keyof Pick<IWorkspace, 'name' | 'description' | 'createdAt'>;
type WorkspaceOrderDirection = ITableOrderDirection;

function orderWorkspaces(
  workspace: IWorkspace,
  otherWorkspace: IWorkspace,
  orderBy: WorkspaceOrderBy,
) {
  if (orderBy === 'createdAt') {
    const workspaceProp = workspace[orderBy];
    const otherWorkspaceProp = otherWorkspace[orderBy];

    return Date.parse(workspaceProp) - Date.parse(otherWorkspaceProp);
  }

  const workspaceProp = workspace[orderBy] ?? '';
  const otherWorkspaceProp = otherWorkspace[orderBy] ?? '';

  return workspaceProp.localeCompare(otherWorkspaceProp, undefined, {
    numeric: true,
    sensitivity: 'base',
  });
}

export function getWorkspacesComparator(
  orderDirection: WorkspaceOrderDirection,
  orderBy: WorkspaceOrderBy,
) {
  return function workspacesComparator(workspace: IWorkspace, otherWorkspace: IWorkspace) {
    const orderResult = orderWorkspaces(workspace, otherWorkspace, orderBy);
    const orderSign = orderDirection === 'asc' ? 1 : -1;

    return orderResult * orderSign;
  };
}
