import platformApi from 'api/platformApi/platformApi';
import {
  IWorkspaceServiceParamsPath,
  IWorkspaceServiceResponse,
} from 'api/platformApi/platformApi.types';

export const getWorkspace = async ({
  params: {
    path: { workspaceId },
  },
}: ServiceFnConfig<IWorkspaceServiceParamsPath>) => {
  const axiosResponse = await platformApi.get<IWorkspaceServiceResponse>(
    `/workspaces/${workspaceId}`,
  );
  const apiResponse = axiosResponse.data;
  const apiData = apiResponse;

  return apiData;
};
