import platformApi from 'api/platformApi/platformApi';
import {
  IPlatformApiGenericError,
  IUpdateWorkspaceRequestBody,
  IUpdateWorkspaceResponse,
} from 'api/platformApi/platformApi.types';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { ErrorSchema, InterfaceOperationError, isWorkspaceNameExistsError } from 'utils/httpErrors';
import { CONFLICT } from 'utils/httpStatuses';

export const updateWorkspace = async (
  workspaceId: string,
  workspaceData: IUpdateWorkspaceRequestBody,
  axiosConfig?: AxiosRequestConfig,
) => {
  try {
    const response = await platformApi.put<
      IUpdateWorkspaceResponse,
      AxiosResponse<IUpdateWorkspaceResponse>,
      IUpdateWorkspaceRequestBody
    >(`/workspaces/${workspaceId}/`, workspaceData, axiosConfig);

    return response.data;
  } catch (error) {
    const apiError = UpdateWorkspaceOperationError.unwrapAPIError(error);
    if (apiError) throw new UpdateWorkspaceOperationError(apiError);
    throw error;
  }
};

type UpdateWorkspaceOperationErrorSchema = ErrorSchema<typeof CONFLICT>;

export class UpdateWorkspaceOperationError extends InterfaceOperationError<
  IUpdateWorkspaceRequestBody,
  UpdateWorkspaceOperationErrorSchema
> {
  errorSchema: UpdateWorkspaceOperationErrorSchema = {
    [CONFLICT]: (apiError) => {
      if (isWorkspaceNameExistsError(apiError)) {
        this.scope = 'field';
        this.field = 'name';
        this.message = 'A workspace already exists with this name.';
      }
    },
  };

  constructor(apiError: IPlatformApiGenericError) {
    super(apiError, 'An error occurred and the workspace could not be updated.', 'snackbar');
    this.scopeAPIError();
  }
}
