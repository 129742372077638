import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@mui/material';
import { ComponentProps, forwardRef } from 'react';

interface CheckboxFieldProps extends ComponentProps<typeof Checkbox> {
  label: string;
  helperText?: string;
}

const CheckboxField = forwardRef<HTMLButtonElement, CheckboxFieldProps>(
  ({ helperText, id, label, ...props }, ref) => {
    return (
      <FormControl
        sx={{
          '& .MuiFormControlLabel-root': {
            margin: 0,
          },
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              {...props}
              id={id}
              aria-describedby={`${id}-helper`}
              sx={{ padding: 0.5 }}
              size="small"
              ref={ref}
            />
          }
          label={label}
          sx={{ '& .MuiFormControlLabel-label': { marginLeft: 1.5 } }}
        />

        {helperText && (
          <FormHelperText id={`${id}-helper`} sx={{ marginLeft: 5 }}>
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  },
);

CheckboxField.displayName = 'CheckboxField';

export default CheckboxField;
