import { useMutation, useQueryClient } from '@tanstack/react-query';
import { IUpdateWorkspaceProjectBookmarksServiceBody } from 'api/platformApi/platformApi.types';
import { getWorkspaceProjectByIdQueryKey } from '../workspaceProjectByIdService/useWorkspaceProjectById';
import { updateWorkspaceProjectBookmarks } from './updateWorkspaceProjectBookmarksService';

function useUpdateWorkspaceProjectBookmarks(workspaceId: string, projectId: string) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (workspaceProjectData: IUpdateWorkspaceProjectBookmarksServiceBody) => {
      return updateWorkspaceProjectBookmarks(workspaceId, projectId, workspaceProjectData).then(
        (r) => r.data,
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: getWorkspaceProjectByIdQueryKey({ workspaceId, projectId }),
      });
    },
  });
}

export default useUpdateWorkspaceProjectBookmarks;
