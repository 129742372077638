import platformApi from 'api/platformApi/platformApi';
import {
  IPlatformApiGenericError,
  IUpdateWorkspaceProjectRequestBody,
} from 'api/platformApi/platformApi.types';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  ErrorSchema,
  InterfaceOperationError,
  isProjectNameEmptyError,
  isProjectNameExistsError,
} from 'utils/httpErrors';
import { BAD_REQUEST, CONFLICT, NOT_FOUND } from 'utils/httpStatuses';
import { CreateProjectOperationError } from '../createWorkspaceProjectService/createWorkspaceProjectService';

export const updateWorkspaceProject = async (
  workspaceId: string,
  projectId: string,
  projectData: IUpdateWorkspaceProjectRequestBody,
  axiosConfig?: AxiosRequestConfig,
) => {
  try {
    const response = await platformApi.put<
      null,
      AxiosResponse<null>,
      IUpdateWorkspaceProjectRequestBody
    >(`/workspaces/${workspaceId}/projects/${projectId}`, projectData, axiosConfig);

    return response.data;
  } catch (error) {
    const apiError = CreateProjectOperationError.unwrapAPIError(error);

    if (apiError) {
      throw new CreateProjectOperationError(apiError);
    }

    throw error;
  }
};

type UpdateProjectOperationErrorSchema = ErrorSchema<
  typeof BAD_REQUEST | typeof CONFLICT | typeof NOT_FOUND
>;

export class UpdateProjectOperationError extends InterfaceOperationError<
  IUpdateWorkspaceProjectRequestBody,
  UpdateProjectOperationErrorSchema
> {
  errorSchema: UpdateProjectOperationErrorSchema = {
    [BAD_REQUEST]: (apiError) => {
      if (isProjectNameEmptyError(apiError)) {
        this.scope = 'field';
        this.field = 'name';
        this.message = 'A project title is required.';
      }
    },
    [CONFLICT]: (apiError) => {
      if (isProjectNameExistsError(apiError)) {
        this.scope = 'field';
        this.field = 'name';
        this.message = 'A project already exists with this name.';
      }
    },
    [NOT_FOUND]: () => {
      this.scope = 'alert';
      this.message = 'Project not found while trying to update. Make sure that you have access.';
    },
  };

  constructor(apiError: IPlatformApiGenericError) {
    super(apiError);
    this.scopeAPIError();
  }
}
