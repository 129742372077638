import { useQuery } from '@tanstack/react-query';
import { getWorkspaces } from './workspacesService';

function useWorkspaces() {
  return useQuery({
    queryKey: ['workspaces'],
    queryFn: getWorkspaces,
  });
}

export default useWorkspaces;
