import { skipToken, useQuery } from '@tanstack/react-query';
import { IWorkspaceProjectServiceParamsPath } from 'api/platformApi/platformApi.types';
import { getWorkspaceProjectById } from './workspaceProjectByIdService';

export type UseWorkspaceProjectByIdParams = Partial<IWorkspaceProjectServiceParamsPath>;

export const getWorkspaceProjectByIdQueryKey = (params: UseWorkspaceProjectByIdParams) => [
  'workspaces',
  params.workspaceId,
  'projects',
  params.projectId,
];

const useWorkspaceProjectById = (params: UseWorkspaceProjectByIdParams) => {
  const { projectId, workspaceId } = params;

  return useQuery({
    queryKey: getWorkspaceProjectByIdQueryKey(params),
    queryFn:
      projectId && workspaceId
        ? () => getWorkspaceProjectById({ params: { path: { workspaceId, projectId } } })
        : skipToken,
  });
};

export default useWorkspaceProjectById;
