import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Button, Divider, Tab, Tabs } from '@mui/material';
import PageHeader from 'components/PageHeader/PageHeader';
import SearchInput from 'pages/Admin/UsersPage/components/SearchInput/SearchInput';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from 'utils/debounce';
import { UsersPageTabs } from './UsersPage.types';
import AccountsList from './components/AccountsList/AccountsList';
import InvitationsList from './components/InvitationsList/InvitationsList';
import InviteUserDrawer from './components/InviteUserDrawer/InviteUserDrawer';

const UsersPage = () => {
  const [isInviteUserDrawerOpen, setIsInviteUserDrawerOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedTab = (searchParams.get('tab') ?? 'accounts') as UsersPageTabs;
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 500);

  const handleOnChangeTabs = (_: React.SyntheticEvent, tabValue: UsersPageTabs) => {
    setSearchParams(`tab=${tabValue}`);
  };

  return (
    <>
      <PageHeader title="Users" size="small">
        <Tabs value={selectedTab} onChange={handleOnChangeTabs} aria-label="basic tabs example">
          <Tab color="primary" label="Accounts" value="accounts" />
          <Tab label="Invitations" value="invitations" />
        </Tabs>
        <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
        <SearchInput
          value={searchValue}
          placeholder="Search..."
          onChange={(value) => {
            setSearchValue(value);
          }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddOutlinedIcon />}
          onClick={() => {
            setIsInviteUserDrawerOpen(true);
          }}
        >
          Invite user
        </Button>
      </PageHeader>

      {selectedTab === 'accounts' && <AccountsList searchValue={debouncedSearchValue} />}
      {selectedTab === 'invitations' && <InvitationsList searchValue={debouncedSearchValue} />}

      <InviteUserDrawer
        open={isInviteUserDrawerOpen}
        onClose={() => {
          setIsInviteUserDrawerOpen(false);
        }}
        onSuccess={() => {
          setSearchParams('tab=invitations');
        }}
      />
    </>
  );
};

export default UsersPage;
