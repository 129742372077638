import { Box, Grid2Props } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { PropsWithChildren } from 'react';
import {
  CREATING_PROJECTS_VIDEO_URL,
  INVITING_USERS_VIDEO_URL,
  LOGGING_IN_VIDEO_URL,
  MANAGING_WORKSPACES_VIDEO_URL,
} from './DashboardPage.consts';
import DashboardBanner from './components/DashboardBanner';
import DashboardRecentProjects from './components/DashboardRecentProjects/DashboardRecentProjects';
import {
  DashboardWorkspaceMembersStat,
  DashboardWorkspaceProjectsStat,
} from './components/DashboardStat';
import DashboardVideo from './components/DashboardVideo';
import DashboardWorkspaceInfo from './components/DashboardWorkspaceInfo';

const DashboardPage = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={{ xs: 3, md: 4, lg: 5 }}
      marginTop={{ xs: 2, md: 7, lg: 10 }}
    >
      <DashboardBanner />

      <GridContainer spacing={{ xs: 2, sm: 3 }}>
        <GridItem xs={12} sm={12} md={6}>
          <DashboardWorkspaceInfo />
        </GridItem>

        <GridItem xs={12} sm={6} md={3}>
          <DashboardWorkspaceMembersStat />
        </GridItem>

        <GridItem xs={12} sm={6} md={3}>
          <DashboardWorkspaceProjectsStat />
        </GridItem>

        <GridItem xs={12} sm={6} md={3}>
          <DashboardVideo link={INVITING_USERS_VIDEO_URL} gradient="alpha" label="Inviting users" />
        </GridItem>

        <GridItem xs={12} sm={6} md={3}>
          <DashboardVideo
            link={MANAGING_WORKSPACES_VIDEO_URL}
            gradient="beta"
            label="Managing workspaces"
          />
        </GridItem>

        <GridItem xs={12} sm={6} md={3}>
          <DashboardVideo link={LOGGING_IN_VIDEO_URL} gradient="gamma" label="Logging in" />
        </GridItem>

        <GridItem xs={12} sm={6} md={3}>
          <DashboardVideo
            link={CREATING_PROJECTS_VIDEO_URL}
            gradient="delta"
            label="Creating projects"
          />
        </GridItem>
      </GridContainer>

      <DashboardRecentProjects />
    </Box>
  );
};

const GridContainer = ({ children, ...props }: PropsWithChildren<Grid2Props>) => {
  return (
    <Grid container {...props}>
      {children}
    </Grid>
  );
};

const GridItem = ({ children, ...props }: PropsWithChildren<Grid2Props>) => {
  return (
    <Grid {...props}>
      <Box borderRadius={4} height={1} overflow="hidden">
        {children}
      </Box>
    </Grid>
  );
};

export default DashboardPage;
