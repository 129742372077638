import { DoNotDisturbOnOutlined } from '@mui/icons-material';
import { IconButton, MenuItem, TextField, Typography } from '@mui/material';
import useWorkspaces from 'api/platformApi/services/workspacesService/useWorkspaces';
import SelectMultiple from 'components/ui/SelectMultiple/SelectMultiple';
import { FC } from 'react';
import { getWorkspacesComparator } from '../../utils/comparators/workspace';
import * as Styled from './SelectWorkspaces.styles';
import { SelectWorkspacesProps } from './SelectWorkspaces.types';

const SelectWorkspaces: FC<SelectWorkspacesProps> = ({ value, onChange, disabled, sx }) => {
  const workspacesQuery = useWorkspaces();
  const workspacesOptions = workspacesQuery.data?.data ?? [];
  const orderedOptions = workspacesOptions.toSorted(getWorkspacesComparator('asc', 'name'));

  return (
    <SelectMultiple
      label="Workspaces"
      options={orderedOptions}
      value={value}
      onChange={onChange}
      renderInput={(params) => (
        <TextField {...params} label="Select workspaces" placeholder="Search..." variant="filled" />
      )}
      renderOption={(props, option) => (
        <MenuItem {...props}>
          {option.logoUrl ? (
            <Styled.WorkspaceLogo src={option.logoUrl} alt={option.name} width="24" height="24" />
          ) : (
            <Styled.DefaultWorkspaceLogo $width={24} $height={24} />
          )}
          {option.name}
        </MenuItem>
      )}
      renderSelectedOption={(selectedOption, onRemoveWorkspace) => (
        <Styled.SelectedWorkspaceRow key={selectedOption.id}>
          <Styled.SelectedWorkspaceDetails>
            {selectedOption.logoUrl ? (
              <Styled.WorkspaceLogo
                src={selectedOption.logoUrl}
                alt={selectedOption.name}
                width="40"
                height="40"
              />
            ) : (
              <Styled.DefaultWorkspaceLogo $width={40} $height={40} />
            )}
            <Typography component="div" variant="bodyMedium">
              {selectedOption.name}
            </Typography>
          </Styled.SelectedWorkspaceDetails>

          {onRemoveWorkspace && (
            <IconButton
              onClick={() => {
                onRemoveWorkspace(selectedOption);
              }}
              color="error"
            >
              <DoNotDisturbOnOutlined />
            </IconButton>
          )}
        </Styled.SelectedWorkspaceRow>
      )}
      onRemoveSelectedOption={(workspace) => {
        onChange(value.filter((selectedOption) => selectedOption.id !== workspace.id));
      }}
      disabled={disabled}
      sx={sx}
    />
  );
};

export default SelectWorkspaces;
