import { skipToken, useInfiniteQuery } from '@tanstack/react-query';
import { snakeCase } from 'lodash-es';
import {
  IWorkspaceProjectsParamsPath,
  IWorkspaceProjectsParamsQuery,
} from '../../platformApi.types';
import { getWorkspaceProjects } from './workspaceProjectsService';

export interface UseWorkspaceProjectsParams
  extends Partial<IWorkspaceProjectsParamsPath>,
    Pick<IWorkspaceProjectsParamsQuery, 'limit'> {
  searchValue?: string;
  sortBy?: 'name' | 'createdAt';
  sortDirection?: 'asc' | 'desc';
}

export const WORKSPACE_PROJECTS_QUERY_KEY_PREFIX = 'workspaceProjects';

export const getWorkspaceProjectsQueryKey = (params: UseWorkspaceProjectsParams) => [
  WORKSPACE_PROJECTS_QUERY_KEY_PREFIX,
  params.workspaceId,
  {
    searchValue: params.searchValue,
    sortBy: params.sortBy,
    sortDirection: params.sortDirection,
  },
];

function useWorkspaceProjects(params: UseWorkspaceProjectsParams) {
  const { workspaceId } = params;

  return useInfiniteQuery({
    queryKey: getWorkspaceProjectsQueryKey(params),
    queryFn: workspaceId
      ? ({ pageParam }) => {
          return getWorkspaceProjects({
            params: {
              path: {
                workspaceId,
              },
              query: {
                ...transformParamsToServiceQuery(params),
                nextCursor: pageParam.length > 0 ? pageParam : undefined,
              },
            },
          });
        }
      : skipToken,
    initialPageParam: '',
    getNextPageParam: (lastPage) => lastPage.nextCursor,
  });
}

/**
 * API query fields and thus service query fields are using snake case.
 * To comply with our camel case convention, we need to transform the query fields to snake case.
 * Objects returned from the API also use camelCase fields, so we need to transform them to snake case.
 */
function transformParamsToServiceQuery(
  params: UseWorkspaceProjectsParams,
): IWorkspaceProjectsParamsQuery {
  const name = (params.searchValue ?? '').trim().length > 0 ? params.searchValue : undefined;
  const limit = params.limit ?? undefined;
  const direction = params.sortDirection
    ? (params.sortDirection.toUpperCase() as Uppercase<
        NonNullable<UseWorkspaceProjectsParams['sortDirection']>
      >)
    : undefined;
  const sortProperty = params.sortBy
    ? (snakeCase(params.sortBy) as SnakeCase<NonNullable<UseWorkspaceProjectsParams['sortBy']>>)
    : undefined;

  return {
    direction,
    limit,
    name,
    sortProperty,
  };
}

export default useWorkspaceProjects;
