import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { IUpdateWorkspaceProjectRequestBody } from 'api/platformApi/platformApi.types';
import { CreateProjectOperationError } from '../createWorkspaceProjectService/createWorkspaceProjectService';
import { updateWorkspaceProject } from './updateWorkspaceProjectService';

function useUpdateWorkspaceProject(
  workspaceId: string,
  projectId: string,
  mutationOptions?: UseMutationOptions<
    unknown,
    CreateProjectOperationError,
    IUpdateWorkspaceProjectRequestBody
  >,
) {
  return useMutation({
    ...mutationOptions,
    mutationKey: ['updateWorkspaceProject', { workspaceId, projectId }],
    mutationFn: (workspaceProjectData: IUpdateWorkspaceProjectRequestBody) => {
      return updateWorkspaceProject(workspaceId, projectId, workspaceProjectData);
    },
  });
}

export default useUpdateWorkspaceProject;
