import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import {
  ICreateWorkspaceRequestBody,
  ICreateWorkspaceResponse,
} from 'api/platformApi/platformApi.types';
import { CreateWorkspaceOperationError, createWorkspace } from './createWorkspaceService';

function useCreateWorkspace(
  mutationOptions?: UseMutationOptions<
    ICreateWorkspaceResponse,
    CreateWorkspaceOperationError,
    ICreateWorkspaceRequestBody
  >,
) {
  return useMutation({
    ...mutationOptions,
    mutationKey: ['createWorkspace'],
    mutationFn: (workspaceData: ICreateWorkspaceRequestBody) => {
      return createWorkspace(workspaceData);
    },
  });
}

export default useCreateWorkspace;
