import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  IUpdateWorkspaceRequestBody,
  IUpdateWorkspaceResponse,
} from 'api/platformApi/platformApi.types';
import { CreateWorkspaceOperationError } from '../createWorkspaceService/createWorkspaceService';
import { updateWorkspace } from './updateWorkspaceService';

function useUpdateWorkspace(
  workspaceId: string,
  mutationOptions?: UseMutationOptions<
    IUpdateWorkspaceResponse,
    CreateWorkspaceOperationError,
    IUpdateWorkspaceRequestBody
  >,
) {
  return useMutation({
    ...mutationOptions,
    mutationKey: ['updateWorkspace', { workspaceId }],
    mutationFn: (data: IUpdateWorkspaceRequestBody) => {
      return updateWorkspace(workspaceId, data);
    },
  });
}

export default useUpdateWorkspace;
