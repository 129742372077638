import platformApi from 'api/platformApi/platformApi';
import {
  IWorkspaceProjectServiceParamsPath,
  IWorkspaceProjectServiceResponse,
} from 'api/platformApi/platformApi.types';

export const getWorkspaceProjectById = async ({
  params: {
    path: { projectId, workspaceId },
  },
}: ServiceFnConfig<IWorkspaceProjectServiceParamsPath>) => {
  const networkResponse = await platformApi.get<IWorkspaceProjectServiceResponse>(
    `/workspaces/${workspaceId}/projects/${projectId}`,
  );
  const apiResponse = networkResponse.data;
  const apiData = apiResponse;

  return apiData;
};
