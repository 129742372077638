import { AxiosError, isAxiosError } from 'axios';
import { IPlatformApiGenericError } from '../api/platformApi/platformApi.types';
import * as HttpStatuses from './httpStatuses';

type HttpStatusesCodes = (typeof HttpStatuses)[keyof typeof HttpStatuses];

export type ErrorScope = 'alert' | 'field' | 'snackbar';
export type ErrorSchema<Codes extends HttpStatusesCodes> = Record<Codes, ErrorSchemaResolver>;
export type ErrorSchemaResolver = (apiError: IPlatformApiGenericError) => void;

/**
 * @class InterfaceOperationError
 * @template OperationData - The data structure of the operation. eg. ICreateWorkspaceRequestBody
 * @template OperationErrorSchema - The schema used to resolve specific HTTP statuses and how they should be shown to the user.
 * @extends Error
 * @abstract
 *
 * @description
 * A base class for handling operation-specific errors derived from platform APIs.
 * Subclasses must extend this class and implement the `errorSchema` property to
 * define how each API error should be handled. Rephrasing and customizing the error
 * message where it's necessary. In case the error is not contemplated in the `errorSchema`,
 * the default error message with an alert scope will be returned.
 *
 * @property {'alert' | 'field' | 'snackbar'} scope - The scope in which the error should be displayed.
 * @property {keyof OperationData} [field] - Optional field to indicate a specific operation data field related to the error.
 * @property {OperationErrorSchema} errorSchema - A property that subclasses must implement to define how specific HTTP statuses should be handled.
 *
 * @constructor
 * @param {IPlatformApiGenericError} apiError - The API error that occurred.
 * @param {string} [defaultMessage='An error occurred, please try again.'] - A default error message.
 *
 * @example
 *
 * ```typescript
 * type CreateProjectOperationErrorSchema = ErrorSchema<typeof CONFLICT>;
 *
 * export class CreateProjectOperationError extends InterfaceOperationError<
 *   ICreateWorkspaceProjectRequestBody,
 *   CreateProjectOperationErrorSchema
 * > {
 *   public errorSchema: CreateProjectOperationErrorSchema = {
 *     [CONFLICT]: (apiError) => {
 *       if (apiError.message.includes('Project with name')) {
 *         this.scope = 'field';
 *         this.field = 'name';
 *         this.message = 'A project already exists with this name.';
 *       }
 *     },
 *   };
 *
 *   constructor(apiError: IPlatformApiGenericError) {
 *     super(apiError);
 *     this.scopeAPIError(apiError);
 *   }
 * }
 * ```
 */
export abstract class InterfaceOperationError<
  OperationData extends object,
  OperationErrorSchema extends Partial<ErrorSchema<HttpStatusesCodes>>,
> extends Error {
  public scope: ErrorScope;
  public field?: keyof OperationData;
  public abstract readonly errorSchema: OperationErrorSchema;

  private static isAPIError(error: unknown): error is AxiosError<IPlatformApiGenericError> {
    if (!isAxiosError(error)) return false;
    if (!error.response?.data) return false;

    const errorData = error.response.data as unknown;

    if (typeof errorData !== 'object' || errorData === null) return false;

    return 'message' in errorData && 'statusCode' in errorData;
  }

  static unwrapAPIError(error: unknown) {
    if (InterfaceOperationError.isAPIError(error)) {
      return error.response?.data;
    }

    if (error instanceof InterfaceOperationError) {
      return error.cause as IPlatformApiGenericError | undefined;
    }

    return undefined;
  }

  constructor(
    apiError: IPlatformApiGenericError,
    defaultMessage = 'An error occurred, please try again.',
    defaultScope: ErrorScope = 'alert',
  ) {
    super(defaultMessage, { cause: apiError });
    this.scope = defaultScope;
  }

  protected scopeAPIError() {
    const apiError = this.cause as IPlatformApiGenericError;
    const schemaResolver = this.errorSchema[apiError.statusCode as HttpStatusesCodes];
    if (schemaResolver) schemaResolver(apiError);
  }
}

/*
  ==============================================================================
  API Errors
  ==============================================================================
*/

/*
  Invitation errors
  ==============================================================================
*/
export const isInvitationUserExistsError = (error: IPlatformApiGenericError) =>
  /already(.*)member(.*)organization/i.test(error.message.toString());

export const isInvitationEmailExistsError = (error: IPlatformApiGenericError) =>
  /invitation(.*)with(.*)email/i.test(error.message.toString());

/*
  Organization errors
  ==============================================================================
*/
export const isOrganizationNameExistsError = (error: IPlatformApiGenericError) =>
  /organization(.*)name(.*)exists/i.test(error.message.toString());

export const isOrganizationAdminExistsError = (error: IPlatformApiGenericError) =>
  /invitation(.*)email(.*)exists/i.test(error.message.toString());
/*
  Project errors
  ==============================================================================
*/
export const isProjectNameExistsError = (error: IPlatformApiGenericError) =>
  /project(.*)name(.*)exists/i.test(error.message.toString());

export const isProjectNameEmptyError = (error: IPlatformApiGenericError) =>
  /name(.*)not(.*)empty/i.test(error.message.toString());

/*
  Workspace errors
  ==============================================================================
*/
export const isWorkspaceAccessError = (error: IPlatformApiGenericError) =>
  /cannot(.*)access(.*)workspace/i.test(error.message.toString());

export const isWorkspaceNameExistsError = (error: IPlatformApiGenericError) =>
  /workspace(.*)name(.*)exists/i.test(error.message.toString());
