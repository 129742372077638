import { RouteOutlined } from '@mui/icons-material';
import { Box, Skeleton, Typography } from '@mui/material';
import { FC } from 'react';
import { PipelineStatesWrapperProps } from './ PipelineStatesWrapper.types';

const PipelineStatesWrapper: FC<PipelineStatesWrapperProps> = ({
  pipelines,
  isLoading,
  isError,
  children,
}) => {
  if (isLoading) {
    return (
      <Box display="flex" flexDirection="column" gap={3}>
        {Array.from({ length: 9 }).map((_, index) => (
          <Box key={index} display="flex" gap={2}>
            <Skeleton width={24} height={24} sx={{ transform: 'none', flexShrink: 0 }} />
            <Skeleton variant="text" width="100%" sx={{ transform: 'none' }} />
          </Box>
        ))}
      </Box>
    );
  }

  if (isError) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <RouteOutlined />
        <Typography variant="bodyMedium" color="text.primary" textAlign="center" maxWidth={320}>
          An error occurred and the pipelines could not be loaded. Please try refreshing the page.
        </Typography>
      </Box>
    );
  }

  if (pipelines.length === 0) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <RouteOutlined />
        <Typography variant="bodyMedium" color="text.primary" textAlign="center" maxWidth={320}>
          No pipelines have been added to this workspace. Launch Pathways from the “Tools” tab of
          your Monks.Flow workspace to create a workspace pipeline.
        </Typography>
      </Box>
    );
  }

  return children;
};

export default PipelineStatesWrapper;
