import { skipToken, useQuery } from '@tanstack/react-query';
import { IWorkspaceServiceParamsPath } from 'api/platformApi/platformApi.types';
import { getWorkspaceMetrics } from './workspaceMetricsService';

export type UseWorkspaceMetricsParams = Partial<IWorkspaceServiceParamsPath>;

export const getWorkspaceMetricsQueryKey = (params: UseWorkspaceMetricsParams) => [
  'workspaces',
  params.workspaceId,
  'metrics',
];

const useWorkspaceMetrics = (params: UseWorkspaceMetricsParams) => {
  const { workspaceId } = params;

  return useQuery({
    queryKey: getWorkspaceMetricsQueryKey(params),
    queryFn: workspaceId
      ? () => getWorkspaceMetrics({ params: { path: { workspaceId } } })
      : skipToken,
  });
};

export default useWorkspaceMetrics;
