import { skipToken, useQuery } from '@tanstack/react-query';
import { IWorkspaceServiceParamsPath } from 'api/platformApi/platformApi.types';
import { getWorkspace } from './workspaceService';

export type UseWorkspaceParams = Partial<IWorkspaceServiceParamsPath>;

export const WORKSPACE_QUERY_KEY_PREFIX = 'workspace';

export const getWorkspaceQueryKey = (params: UseWorkspaceParams) => [
  WORKSPACE_QUERY_KEY_PREFIX,
  params.workspaceId,
];

const useWorkspace = (params: UseWorkspaceParams) => {
  const { workspaceId } = params;

  return useQuery({
    queryKey: getWorkspaceQueryKey(params),
    queryFn: workspaceId ? () => getWorkspace({ params: { path: { workspaceId } } }) : skipToken,
  });
};

export default useWorkspace;
