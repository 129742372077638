import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Tooltip,
  Typography,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { IUserRole, IUserWithRole } from 'api/platformApi/platformApi.types';
import useUpdateUser from 'api/platformApi/services/updateUserService/useUpdateUser';
import useUsers, { getUsersQueryKey } from 'api/platformApi/services/usersService/useUsers';
import AppDrawer from 'components/AppDrawer/AppDrawer';
import AppDrawerContent from 'components/AppDrawer/components/AppDrawerContent/AppDrawerContent';
import AppDrawerHeader from 'components/AppDrawer/components/AppDrawerHeader/AppDrawerHeader';
import SelectWorkspaces from 'components/SelectWorkspaces/SelectWorkspaces';
import CheckboxField from 'components/ui/CheckboxField/CheckboxField';
import MFButtonLoader from 'components/ui/MFButtonLoader/MFButtonLoader';
import RHFPermissionsField from 'components/ui/rhf-components/RHFPermissionsField/RHFPermissionsField';
import useSnackbarContext from 'contexts/snackbar/useSnackbarContext';
import { FC } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as Styled from './ManageUserDrawer.styles';
import { IEditUserForm, ManageUserDrawerProps } from './ManageUserDrawer.types';

const hasAdminRole = (user: Maybe<IUserWithRole>) => user?.role === 'admin';
const hasDefaultRole = (user: Maybe<IUserWithRole>) => user?.role === 'default';

const ManageUserDrawer: FC<ManageUserDrawerProps> = (props) => {
  const { open, user } = props;

  const queryClient = useQueryClient();
  const snackbar = useSnackbarContext();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
    reset,
    watch,
  } = useForm<IEditUserForm>({
    mode: 'all',
    defaultValues: {
      isAdmin: false,
      permissions: {},
      workspaces: [],
    },
    values: {
      isAdmin: hasAdminRole(user),
      permissions:
        user?.permissions.reduce(
          (permissions, key) => {
            permissions[key] = true;
            return permissions;
          },
          {} as Record<string, boolean>,
        ) ?? {},
      workspaces: user?.workspaces ?? [],
    },
  });

  const handleClose = () => {
    reset();
    props.onClose();
  };

  const usersQuery = useUsers(undefined, { enabled: open });
  const users = usersQuery.data?.data ?? [];

  const isOnlyOneAdminLeft = users.filter(hasAdminRole).length === 1;
  const shouldDisableCheckboxPermissions = !hasDefaultRole(user) && isOnlyOneAdminLeft;
  const watchedIsAdmin = watch('isAdmin');
  const shouldDisplayRevokeAlert =
    !shouldDisableCheckboxPermissions && hasAdminRole(user) && !watchedIsAdmin;
  const isSubmitDisabled = !isValid || isSubmitting;

  const updateUserMutation = useUpdateUser(
    { userId: user?.id ?? '' },
    {
      onError: () => {
        snackbar.error('An error occurred and the changes could not be saved');
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: getUsersQueryKey(),
        });
        handleClose();
        snackbar.success('Your changes were saved! User updated');
      },
    },
  );

  const onEditUserSubmit: SubmitHandler<IEditUserForm> = async (data) => {
    const name = user?.name;
    const workspacesIds = data.workspaces.map((workspace) => workspace.id);
    const permissions = Object.entries(data.permissions ?? {})
      .filter(([, checked]) => checked)
      .map(([permissionKey]) => permissionKey);

    const currentUserRole = user?.role;
    let updatedUserRole: IUserRole = data.isAdmin ? 'admin' : 'default';

    if (isOnlyOneAdminLeft && currentUserRole === 'admin') {
      updatedUserRole = 'admin';
    }

    await updateUserMutation.mutateAsync({
      name,
      role: updatedUserRole,
      permissions,
      workspacesIds,
    });
  };

  const isAdminChecked = watch('isAdmin');

  return (
    <AppDrawer open={open} onClose={handleClose} leftSidebar={false}>
      <Styled.Form onSubmit={handleSubmit(onEditUserSubmit)}>
        <AppDrawerHeader title="Edit user" onClose={handleClose}></AppDrawerHeader>

        <AppDrawerContent>
          <Box display="flex" flexDirection="column" gap={3}>
            <Box display="flex" flexDirection="column" gap={1}>
              <Styled.UserCard>
                <Styled.UserDetails>
                  <Styled.Avatar>{user?.name.charAt(0).toUpperCase()}</Styled.Avatar>

                  <Styled.UserInfo>
                    <Typography variant="bodyMedium" color="text.primary">
                      {user?.name}
                    </Typography>
                    <Typography variant="bodySmall" color="text.secondary">
                      {user?.email}
                    </Typography>
                  </Styled.UserInfo>
                </Styled.UserDetails>
              </Styled.UserCard>

              {shouldDisplayRevokeAlert && (
                <Styled.PermissionsRevokedAlert
                  severity="warning"
                  variant="standard"
                  icon={<WarningOutlinedIcon />}
                >
                  You are revoking the user&apos;s admin permissions
                </Styled.PermissionsRevokedAlert>
              )}
            </Box>

            <Controller
              name="workspaces"
              control={control}
              render={({ field }) => (
                <SelectWorkspaces
                  value={field.value}
                  onChange={field.onChange}
                  disabled={isSubmitting}
                />
              )}
            />

            <Box display="flex" flexDirection="column" gap={0.5}>
              <Typography variant="titleMedium">Administrative permission</Typography>

              <Accordion>
                <AccordionSummary>
                  <Typography variant="titleSmall">Monks.Flow</Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <Controller
                    control={control}
                    disabled={shouldDisableCheckboxPermissions}
                    name="isAdmin"
                    render={({ field }) => (
                      <Tooltip
                        title="There must be at least one administrator"
                        placement="bottom-start"
                        disableHoverListener={!shouldDisableCheckboxPermissions}
                      >
                        <span>
                          <CheckboxField
                            checked={field.value}
                            disabled={field.disabled}
                            helperText="This user will automatically be granted all administrative permissions"
                            id="platform-administrator"
                            label="Platform administrator"
                            onBlur={field.onBlur}
                            onChange={field.onChange}
                            ref={field.ref}
                            value="platform-administrator"
                          />
                        </span>
                      </Tooltip>
                    )}
                  />

                  <Box display="flex" flexDirection="column" gap={2} marginLeft={4} marginTop={2}>
                    <RHFPermissionsField
                      checked={isAdminChecked}
                      control={control}
                      disabled={isAdminChecked}
                      name="permissions"
                    />
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>
        </AppDrawerContent>

        <Styled.CustomAppDrawerFooter>
          <Styled.SaveChangesButton
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            disabled={isSubmitDisabled}
          >
            {isSubmitting ? <MFButtonLoader /> : `Save changes`}
          </Styled.SaveChangesButton>
        </Styled.CustomAppDrawerFooter>
      </Styled.Form>
    </AppDrawer>
  );
};

export default ManageUserDrawer;
