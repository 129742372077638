import { Button, Skeleton } from '@mui/material';
import { IApp } from 'api/platformApi/platformApi.types';
import useUserApps from 'api/platformApi/services/userAppsService/useUserApps';
import useWorkspaceProjectById from 'api/platformApi/services/workspaceProjectByIdService/useWorkspaceProjectById';
import PageHeader from 'components/PageHeader/PageHeader';
import { format } from 'date-fns';
import { useProjectAccessLevel } from 'hooks/useAccessLevel';
import ErrorPage from 'pages/ErrorPage/ErrorPage';
import Bookmarks from 'pages/ViewProjectPage/components/Bookmarks/Bookmarks';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import createIntentUrl from 'utils/intentUrl';
import { routes } from 'utils/routes';
import { generateSkeletonArray } from 'utils/skeleton';
import { useActiveWorkspace } from 'utils/useActiveWorkspace';
import * as Styled from './ViewProjectPage.styles';
import { ViewProjectPageTabs } from './ViewProjectPage.types';
import { getViewProjectError } from './ViewProjectPage.utils';
import ProjectFiles from './components/ProjectFiles/ProjectFiles';
import ProjectOptions from './components/ProjectOptions/ProjectOptions';
import ShareProject from './components/ShareProject/ShareProject';
import ToolCard from './components/ToolCard/ToolCard';

const ViewProjectPage = () => {
  const { projectId = '' } = useParams();
  const activeWorkspace = useActiveWorkspace();
  const workspaceId = activeWorkspace?.id ?? '';
  const workspaceSlug = activeWorkspace?.slug ?? '';

  const projectQuery = useWorkspaceProjectById({ workspaceId, projectId });
  const projectAccesLevel = useProjectAccessLevel({ workspaceId, projectId });
  const appsQuery = useUserApps();
  const apps = appsQuery.data?.data ?? [];
  const projectLevelApps = apps.filter((app) => app.manifest?.intents?.openProject);

  const [searchParams, setSearchParams] = useSearchParams();
  const selectedTab = (searchParams.get('tab') ?? 'overview') as ViewProjectPageTabs;

  const handleOpenApp = (app: IApp) => {
    const defaultUrl = `${app.hostedUrl}?monksFlowWorkspaceSlug=${workspaceSlug}&monksFlowProjectId=${projectId}`;
    const url = createIntentUrl(app, 'openProject', { workspaceSlug, projectId }) ?? defaultUrl;

    window.open(url, '_blank');
  };

  const skeletonApps = generateSkeletonArray<IApp>(3);
  const appsToDisplay = appsQuery.isLoading ? skeletonApps : projectLevelApps;

  const createdDate =
    projectQuery.data?.createdAt &&
    format(new Date(projectQuery.data.createdAt), "MM/dd/yyyy 'at' hh:mma");

  if (projectQuery.isError) {
    const error = getViewProjectError(projectQuery.error);

    return (
      <ErrorPage
        title={error.title}
        description={error.description}
        content={
          <Link to={routes.projects(workspaceSlug)}>
            <Button variant="contained" color="black">
              Back to projects
            </Button>
          </Link>
        }
      />
    );
  }

  return (
    <>
      <PageHeader
        title={projectQuery.data?.name}
        backTo={routes.projects(workspaceSlug)}
        isLoading={projectQuery.isLoading}
      >
        <div>
          <Styled.TabButton
            onClick={() => {
              setSearchParams('tab=overview');
            }}
            variant={selectedTab === 'overview' ? 'contained' : 'text'}
            disabled={projectQuery.isLoading}
          >
            Overview
          </Styled.TabButton>
          <Styled.TabButton
            onClick={() => {
              setSearchParams('tab=files');
            }}
            variant={selectedTab === 'files' ? 'contained' : 'text'}
            disabled={projectQuery.isLoading}
          >
            Files
          </Styled.TabButton>
        </div>
        <Styled.Divider />
        <ShareProject project={projectQuery.data} />
        <Styled.Divider />
        <ProjectOptions />
      </PageHeader>

      <Styled.Container>
        {selectedTab === 'overview' && (
          <>
            <Bookmarks
              bookmarks={projectQuery.data?.bookmarks ?? []}
              disabled={projectAccesLevel !== 'write'}
              isLoading={projectQuery.isLoading}
            />

            <Styled.DescriptionToolsContainer>
              <Styled.Card>
                <Styled.CardTitle $isLoading={projectQuery.isLoading}>Description</Styled.CardTitle>
                {projectQuery.isLoading ? (
                  <Styled.SkeletonContainer>
                    <Skeleton variant="rounded" width="100%" height={16} />
                    <Skeleton variant="rounded" width="100%" height={16} />
                    <Skeleton variant="rounded" width="85%" height={16} />
                    <Skeleton variant="rounded" width="30%" height={16} sx={{ marginTop: '8px' }} />
                  </Styled.SkeletonContainer>
                ) : (
                  <>
                    <Styled.Description>{projectQuery.data?.description}</Styled.Description>
                    <Styled.CreatedBy>
                      Created by {projectQuery.data?.owner.name} on {createdDate}
                    </Styled.CreatedBy>
                  </>
                )}
              </Styled.Card>

              <Styled.PinnedToolsCard>
                <Styled.CardTitle $isLoading={appsQuery.isLoading}>Project tools</Styled.CardTitle>
                <Styled.ToolsContainer>
                  {appsToDisplay.map((app) => (
                    <ToolCard
                      key={app.id}
                      name={app.name}
                      logoUrl={app.logo}
                      description={app.description}
                      onClick={() => {
                        handleOpenApp(app);
                      }}
                      isLoading={appsQuery.isLoading}
                    />
                  ))}
                </Styled.ToolsContainer>
              </Styled.PinnedToolsCard>
            </Styled.DescriptionToolsContainer>
          </>
        )}

        {selectedTab === 'files' && <ProjectFiles />}
      </Styled.Container>
    </>
  );
};

export default ViewProjectPage;
