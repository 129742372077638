import platformApi from 'api/platformApi/platformApi';
import {
  IWorkspaceMetricsServiceParamsPath,
  IWorkspaceMetricsServiceResponse,
} from 'api/platformApi/platformApi.types';

export const getWorkspaceMetrics = async ({
  params: {
    path: { workspaceId },
  },
}: ServiceFnConfig<IWorkspaceMetricsServiceParamsPath>) => {
  const axiosResponse = await platformApi.get<IWorkspaceMetricsServiceResponse>(
    `/workspaces/${workspaceId}/metrics`,
  );
  const apiResponse = axiosResponse.data;
  const apiData = apiResponse;

  return apiData;
};
