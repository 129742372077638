import { UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  ICreateWorkspaceProjectRequestBody,
  ICreateWorkspaceProjectResponse,
} from 'api/platformApi/platformApi.types';
import { getWorkspaceMetricsQueryKey } from '../workspaceMetricsService/useWorkspaceMetrics';
import {
  CreateProjectOperationError,
  createWorkspaceProject,
} from './createWorkspaceProjectService';

function useCreateWorkspaceProject(
  workspaceId: string,
  mutationOptions?: UseMutationOptions<
    ICreateWorkspaceProjectResponse,
    CreateProjectOperationError,
    ICreateWorkspaceProjectRequestBody
  >,
) {
  const { onSuccess } = mutationOptions ?? {};
  const queryClient = useQueryClient();

  return useMutation({
    ...mutationOptions,
    mutationKey: ['createWorkspaceProject', { workspaceId }],
    mutationFn: (workspaceProjectData: ICreateWorkspaceProjectRequestBody) => {
      return createWorkspaceProject(workspaceId, workspaceProjectData);
    },
    onSuccess(...args) {
      void queryClient.invalidateQueries({
        queryKey: getWorkspaceMetricsQueryKey({ workspaceId }),
      });
      onSuccess?.(...args);
    },
  });
}

export default useCreateWorkspaceProject;
