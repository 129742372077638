import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import {
  IUpdateUserServiceParamsPath,
  IUpdateUserServiceRequestBody,
} from 'api/platformApi/platformApi.types';
import { updateUserService } from './updateUserService';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface UseUpdateUserParams extends IUpdateUserServiceParamsPath {}

const useUpdateUser = (
  params: UseUpdateUserParams,
  mutationOptions?: UseMutationOptions<unknown, unknown, IUpdateUserServiceRequestBody>,
) => {
  return useMutation({
    ...mutationOptions,
    mutationFn: (userData: IUpdateUserServiceRequestBody) => {
      return updateUserService({
        params: {
          path: {
            userId: params.userId,
          },
        },
        body: userData,
      });
    },
  });
};

export default useUpdateUser;
