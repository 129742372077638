import platformApi from 'api/platformApi/platformApi';
import {
  IWorkspaceProjectsParamsPath,
  IWorkspaceProjectsParamsQuery,
  IWorkspaceProjectsResponse,
} from 'api/platformApi/platformApi.types';

export const getWorkspaceProjects = async ({
  params: {
    path: { workspaceId },
    query: { limit, direction, name, nextCursor, sortProperty },
  },
}: ServiceFnConfig<IWorkspaceProjectsParamsPath, IWorkspaceProjectsParamsQuery>) => {
  if (!workspaceId) {
    throw new Error('workspaceId is required');
  }

  const response = await platformApi.get<IWorkspaceProjectsResponse>(
    `/workspaces/${workspaceId}/projects`,
    {
      params: { limit, direction, name, nextCursor, sortProperty },
    },
  );

  return response.data;
};
